import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
<path d="M4345 9704 c-16 -2 -82 -9 -145 -15 -140 -14 -339 -63 -493 -123 -63
-24 -123 -49 -133 -54 -204 -111 -257 -144 -389 -247 -85 -66 -244 -224 -318
-315 -190 -234 -334 -538 -403 -855 -28 -125 -29 -140 -29 -400 0 -256 1 -277
28 -401 112 -536 416 -986 867 -1286 592 -395 1369 -444 2045 -130 365 170
651 382 1022 757 276 280 485 533 748 905 154 219 204 281 319 395 186 183
377 284 676 355 47 11 95 20 107 20 12 0 27 9 34 21 10 20 17 21 73 15 104
-10 248 50 346 144 50 48 100 122 100 148 0 15 -165 32 -304 32 -279 0 -575
-71 -821 -197 -222 -114 -357 -213 -538 -397 -51 -52 -91 -86 -96 -80 -5 5
-65 77 -133 159 -69 83 -157 184 -197 226 -39 42 -71 80 -71 84 0 20 244 247
410 381 254 206 600 383 872 448 182 43 247 50 438 50 150 0 207 -4 305 -22
815 -155 1383 -843 1379 -1672 -1 -183 -32 -356 -95 -533 -193 -543 -662 -957
-1224 -1078 -183 -39 -446 -46 -640 -18 -265 40 -580 166 -825 332 -87 59
-244 176 -280 209 -14 13 -67 60 -117 105 l-91 83 -108 -121 c-60 -67 -109
-126 -109 -132 0 -24 261 -250 420 -363 356 -253 757 -418 1120 -459 742 -84
1419 192 1869 763 204 259 349 592 400 922 135 869 -312 1732 -1099 2120 -319
157 -627 221 -1000 206 -88 -3 -196 -12 -240 -20 -544 -96 -1028 -366 -1512
-840 l-103 -100 -122 121 c-486 480 -1017 769 -1543 838 -121 16 -344 27 -400
19z m305 -354 c348 -36 703 -186 1044 -439 145 -108 486 -420 486 -444 0 -5
-42 -59 -93 -120 -111 -134 -157 -193 -157 -201 0 -13 201 -277 209 -274 5 2
54 59 109 128 55 69 114 141 131 160 l31 35 26 -30 c15 -16 98 -104 184 -195
87 -91 174 -185 194 -209 36 -43 36 -44 20 -70 -10 -14 -21 -30 -26 -36 -5 -5
-45 -59 -90 -120 -108 -147 -203 -265 -279 -349 l-36 -39 -77 94 c-105 127
-94 113 -205 262 -54 72 -130 179 -170 237 -140 205 -270 355 -410 474 -91 77
-236 186 -246 186 -4 0 -20 9 -36 20 -53 38 -233 123 -344 162 -232 82 -495
117 -752 98 -75 -5 -138 -12 -141 -15 -11 -11 36 -93 82 -143 61 -65 136 -111
240 -148 63 -22 82 -25 97 -16 30 19 75 15 90 -8 10 -15 40 -26 109 -40 130
-27 267 -73 375 -126 153 -75 230 -131 346 -250 107 -112 167 -188 315 -400
99 -143 286 -397 304 -414 3 -3 32 -39 65 -80 33 -41 72 -88 87 -105 16 -16
28 -37 28 -45 0 -29 -275 -282 -450 -415 -638 -481 -1332 -590 -1962 -309
-483 216 -846 674 -946 1194 -30 154 -33 181 -33 346 -1 540 282 1065 741
1371 334 224 721 316 1140 273z"/>
<path d="M3848 4990 c-136 -25 -236 -74 -325 -163 -111 -108 -167 -280 -145
-445 27 -208 164 -370 372 -443 56 -20 84 -23 210 -23 130 0 153 3 225 27 44
15 107 42 140 60 l60 34 3 216 2 217 -112 0 -113 0 3 -143 c3 -123 2 -146 -12
-160 -28 -27 -96 -42 -191 -41 -102 1 -171 25 -238 84 -138 121 -137 369 1
491 65 57 139 82 242 83 98 1 158 -17 231 -70 26 -19 50 -34 54 -34 4 0 41 30
83 67 l75 67 -47 42 c-98 89 -215 133 -371 139 -55 2 -121 0 -147 -5z"/>
<path d="M1470 4455 l0 -535 115 0 115 0 2 312 3 311 129 -214 c192 -318 177
-299 240 -299 l53 0 155 260 c85 143 158 257 162 253 3 -3 6 -145 6 -315 l0
-308 115 0 115 0 0 535 0 535 -104 0 -104 0 -55 -92 c-30 -51 -114 -194 -187
-318 -73 -124 -138 -231 -145 -238 -14 -16 6 -47 -283 436 l-127 212 -102 0
-103 0 0 -535z"/>
<path d="M2939 4986 c-2 -2 -3 -243 -1 -535 l2 -531 120 0 120 0 0 535 0 535
-118 0 c-65 0 -121 -2 -123 -4z"/>
<path d="M4630 4455 l0 -535 120 0 120 0 0 215 0 215 245 0 245 0 0 -215 0
-215 125 0 125 0 0 535 0 535 -125 0 -125 0 0 -215 0 -215 -222 0 c-123 0
-233 3 -245 6 -23 6 -23 7 -23 215 l0 209 -120 0 -120 0 0 -535z"/>
<path d="M5740 4890 l0 -100 170 0 c129 0 170 -3 170 -12 1 -7 0 -203 0 -435
l-1 -423 126 0 125 0 2 433 3 432 172 3 172 2 3 68 3 67 188 -313 187 -312 0
-190 0 -190 125 0 125 0 0 190 0 190 205 341 c113 188 205 343 205 345 0 2
-54 4 -119 4 l-119 0 -139 -231 c-76 -128 -143 -232 -148 -232 -6 0 -72 104
-149 231 l-138 232 -584 0 -584 0 0 -100z"/>
<path d="M7830 4456 l0 -536 291 0 c232 0 305 3 362 16 204 46 368 200 406
382 16 75 14 218 -4 287 -44 166 -173 297 -355 357 -60 20 -90 22 -382 26
l-318 4 0 -536z m571 320 c102 -23 176 -79 220 -165 31 -61 38 -194 14 -276
-13 -46 -28 -70 -73 -115 -82 -81 -118 -92 -324 -98 l-168 -4 0 329 c0 181 3
333 7 336 12 12 267 7 324 -7z"/>
<path d="M9391 4932 c-29 -68 -115 -260 -141 -317 -21 -45 -262 -586 -291
-652 l-18 -43 127 0 127 0 45 113 46 112 251 3 251 2 17 -42 c9 -24 30 -76 47
-115 l30 -73 129 0 c71 0 129 2 129 3 0 2 -6 17 -14 33 -8 16 -44 97 -81 179
-37 83 -93 209 -125 280 -116 260 -202 451 -228 512 l-27 62 -125 1 -125 0
-24 -58z m233 -392 c42 -101 76 -190 76 -197 0 -10 -36 -13 -159 -13 -88 0
-162 3 -165 8 -7 11 157 403 165 394 4 -4 42 -90 83 -192z"/>
<path d="M10080 4890 l0 -100 170 0 170 0 0 -435 0 -435 125 0 125 0 0 435 0
435 170 0 170 0 0 100 0 100 -465 0 -465 0 0 -100z"/>
<path d="M11421 4967 c-5 -13 -32 -75 -61 -138 -28 -63 -57 -127 -65 -144 -7
-16 -42 -95 -78 -175 -36 -80 -82 -183 -102 -230 -37 -83 -46 -103 -119 -264
-20 -43 -36 -83 -36 -87 0 -5 56 -9 124 -9 l124 0 30 73 c17 39 38 91 47 115
l17 42 250 -2 250 -3 47 -112 46 -113 118 0 117 0 0 29 c0 15 -25 84 -55 152
-106 237 -128 284 -145 324 -46 101 -162 360 -205 458 l-48 107 -124 0 c-120
0 -124 -1 -132 -23z m199 -384 c33 -82 70 -167 81 -191 11 -24 17 -48 14 -53
-4 -5 -78 -9 -166 -9 -127 0 -159 3 -159 14 0 11 41 115 133 333 12 29 25 53
29 53 3 0 34 -66 68 -147z"/>
<path d="M3593 3343 c-10 -4 -13 -52 -13 -194 l0 -189 103 0 c163 0 209 23
209 106 0 38 -33 84 -60 84 -10 0 -7 8 11 25 29 30 36 58 25 100 -12 48 -50
64 -164 68 -54 3 -105 2 -111 0z m216 -38 c48 -24 55 -72 16 -110 -22 -23 -32
-25 -120 -25 l-95 0 0 75 0 75 85 0 c56 0 96 -5 114 -15z m42 -194 c27 -34 24
-75 -7 -100 -23 -18 -41 -21 -130 -21 l-104 0 0 76 0 76 111 -4 c102 -3 112
-5 130 -27z"/>
<path d="M4013 3342 c-23 -3 -23 -4 -23 -193 l0 -189 136 0 c104 0 135 3 132
13 -3 8 -39 13 -121 15 l-117 3 0 74 0 74 103 3 c59 2 102 7 102 13 0 6 -43
11 -102 13 l-103 3 0 74 0 75 116 0 c88 0 115 3 111 13 -3 8 -34 12 -108 12
-57 0 -114 -1 -126 -3z"/>
<path d="M4626 3342 c-17 -16 1 -22 64 -22 l70 0 0 -180 c0 -153 2 -180 15
-180 13 0 15 27 15 180 l0 180 66 0 c48 0 65 3 62 13 -4 9 -43 12 -146 14 -77
1 -143 -1 -146 -5z"/>
<path d="M5076 3341 c-4 -5 30 -89 74 -186 44 -97 80 -181 80 -186 0 -5 9 -9
20 -9 11 0 20 5 20 10 0 6 36 90 80 187 54 118 77 180 70 187 -6 6 -18 -10
-36 -50 -79 -180 -128 -284 -134 -284 -4 0 -40 75 -81 166 -40 91 -76 167 -80
170 -4 2 -10 0 -13 -5z"/>
<path d="M5457 3342 c-9 -2 -17 -8 -17 -13 0 -5 32 -9 70 -9 l70 0 0 -180 c0
-113 4 -180 10 -180 6 0 10 67 10 180 l0 180 71 0 c52 0 70 3 66 13 -3 9 -38
12 -133 12 -71 0 -137 -1 -147 -3z"/>
<path d="M6375 3342 c-3 -3 -5 -90 -5 -194 0 -160 2 -188 15 -188 12 0 15 14
15 65 l0 65 78 0 c99 1 151 22 176 75 29 61 6 129 -55 161 -29 14 -212 28
-224 16z m237 -61 c26 -29 29 -40 25 -73 -10 -72 -56 -98 -174 -98 l-63 0 0
106 0 106 91 -4 c89 -3 91 -4 121 -37z"/>
<path d="M6760 3155 l0 -195 139 0 139 0 23 55 24 55 115 0 115 0 24 -55 c13
-30 30 -55 37 -55 15 0 17 -4 -97 243 -43 94 -68 137 -79 137 -11 0 -40 -53
-97 -177 l-80 -177 -114 3 c-63 1 -115 4 -117 5 -1 2 -3 80 -4 174 -1 133 -4
172 -15 176 -11 4 -13 -30 -13 -189z m492 48 l46 -98 -49 -3 c-27 -2 -71 -2
-98 0 l-49 3 46 98 c24 53 48 97 52 97 4 0 28 -44 52 -97z"/>
<path d="M7384 3341 c-15 -15 4 -21 66 -21 l70 0 0 -180 c0 -113 4 -180 10
-180 6 0 10 67 10 180 l0 180 70 0 c52 0 70 3 70 13 0 11 -30 14 -146 13 -80
0 -147 -3 -150 -5z"/>
<path d="M7767 3342 c-15 -3 -17 -23 -17 -193 0 -119 4 -189 10 -189 6 0 10
35 10 85 l0 85 105 0 c87 0 105 3 105 15 0 12 -18 15 -105 15 l-106 0 3 78 3
77 113 3 c93 2 112 5 112 18 0 13 -16 15 -108 12 -59 -2 -116 -4 -125 -6z"/>
<path d="M8578 3342 l-28 -3 0 -190 c0 -161 2 -189 15 -189 12 0 15 14 15 65
l0 65 73 0 73 0 37 -50 c21 -28 40 -57 44 -65 5 -14 33 -22 33 -9 0 3 -19 32
-42 65 l-41 59 37 33 c31 26 39 41 43 78 7 60 -16 106 -63 125 -33 14 -141 23
-196 16z m182 -37 c37 -19 50 -43 50 -92 0 -75 -46 -103 -167 -103 l-63 0 0
105 0 105 75 0 c48 0 87 -6 105 -15z"/>
<path d="M4378 3323 c-53 -33 -61 -101 -17 -142 11 -10 52 -27 91 -37 39 -10
82 -27 95 -37 23 -18 31 -60 15 -83 -25 -38 -134 -46 -195 -15 -46 24 -60 8
-16 -18 65 -40 168 -38 215 2 14 13 27 38 31 59 8 53 -23 79 -130 112 -90 28
-107 42 -107 90 0 55 99 83 177 51 42 -18 43 -18 43 -5 0 37 -151 54 -202 23z"/>
<path d="M5800 3208 c0 -147 10 -184 63 -222 38 -28 139 -29 180 -2 50 34 61
68 65 219 4 118 2 137 -11 137 -13 0 -16 -23 -19 -146 l-3 -146 -33 -29 c-27
-24 -41 -29 -83 -29 -60 0 -101 19 -117 55 -7 16 -12 77 -12 160 0 113 -2 135
-15 135 -13 0 -15 -21 -15 -132z"/>
<path d="M8165 3326 c-42 -18 -92 -78 -106 -127 -10 -32 -10 -51 -1 -87 18
-66 40 -93 103 -125 91 -48 186 -27 252 55 70 87 38 227 -64 278 -45 23 -138
26 -184 6z m189 -40 c47 -35 66 -74 66 -131 0 -65 -22 -109 -69 -140 -36 -25
-48 -27 -110 -23 -64 3 -74 7 -106 38 -87 84 -67 222 39 272 58 27 133 20 180
-16z"/>
<path d="M8940 3150 c0 -162 2 -190 15 -190 13 0 15 25 15 162 l0 162 33 -54
c19 -30 55 -92 81 -137 26 -46 51 -83 54 -83 4 0 38 54 75 120 104 180 96 180
97 -7 0 -102 4 -163 10 -163 6 0 10 70 10 191 0 142 -3 190 -12 187 -11 -4
-38 -47 -137 -217 l-43 -73 -23 38 c-124 214 -150 254 -161 254 -11 0 -14 -37
-14 -190z"/>
<path d="M11530 921 l0 -231 145 0 c90 0 145 4 145 10 0 6 -43 10 -110 10
l-110 0 -3 223 -2 222 -3 -222 c-2 -216 -3 -223 -22 -223 -19 0 -20 7 -20 214
0 133 -4 217 -10 221 -7 4 -10 -73 -10 -224z"/>
<path d="M11910 1141 c0 -5 48 -75 107 -157 58 -82 129 -180 156 -219 60 -85
56 -80 63 -72 4 3 -15 36 -41 72 -30 43 -45 72 -40 83 9 24 179 261 187 262 4
0 9 -96 11 -212 l2 -213 3 233 c1 127 -2 232 -6 232 -4 0 -54 -65 -111 -145
-56 -80 -106 -145 -109 -145 -4 1 -54 66 -111 145 -58 80 -106 145 -108 145
-2 0 -3 -4 -3 -9z"/>
<path d="M11900 880 c0 -228 18 -246 22 -22 l3 157 110 -155 c61 -85 113 -159
117 -164 4 -6 10 -7 14 -4 7 8 -246 367 -258 368 -5 0 -8 -81 -8 -180z"/>
<path d="M11588 575 c-16 -35 3 -75 35 -75 31 0 47 18 47 52 0 48 -62 66 -82
23z m62 0 c20 -24 4 -65 -25 -65 -18 0 -26 7 -31 25 -7 27 9 55 31 55 7 0 18
-7 25 -15z"/>
<path d="M11708 589 c-35 -20 -12 -100 24 -86 8 4 7 6 -4 6 -22 1 -34 46 -18
66 6 7 21 16 33 18 20 4 20 4 2 5 -11 1 -28 -3 -37 -9z"/>
<path d="M11806 584 c-32 -31 -14 -84 29 -84 28 0 45 19 45 50 0 47 -42 66
-74 34z m54 -8 c20 -26 8 -61 -22 -64 -22 -3 -29 2 -34 22 -11 44 29 74 56 42z"/>
<path d="M11941 550 c2 -50 2 -52 9 -20 l7 35 14 -32 c7 -18 17 -33 21 -33 12
0 10 6 -13 47 -34 61 -39 61 -38 3z"/>
<path d="M12020 580 c-7 -12 -7 -20 -1 -20 5 0 12 -15 14 -32 3 -23 4 -16 5
20 2 56 -1 63 -18 32z"/>
<path d="M12090 591 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M12159 595 c-10 -16 -9 -84 1 -90 6 -3 10 3 10 14 0 27 7 26 32 -1
l20 -23 -16 28 c-13 21 -14 32 -6 50 10 20 10 20 -3 5 -19 -24 -25 -23 -29 5
-2 12 -6 18 -9 12z"/>
<path d="M12240 550 c0 -48 1 -50 28 -49 21 1 22 3 6 6 -17 3 -21 11 -21 43 0
32 4 40 21 43 16 3 15 5 -6 6 -27 1 -28 -1 -28 -49z"/>
<path d="M12322 548 l2 -53 3 48 c4 50 23 63 42 30 9 -16 10 -16 11 -2 0 17
-19 29 -46 29 -10 0 -14 -13 -12 -52z"/>
<path d="M11522 548 c-2 -44 0 -48 21 -47 16 1 17 3 5 6 -12 3 -18 17 -21 46
l-4 42 -1 -47z"/>
<path d="M12071 539 c-9 -27 -9 -30 4 -19 8 7 24 10 35 7 22 -6 25 0 10 23 -6
10 -10 11 -10 3 0 -7 -7 -13 -15 -13 -9 0 -12 6 -9 15 4 8 4 15 1 15 -3 0 -10
-14 -16 -31z"/>
<path d="M11755 525 c0 -14 3 -25 8 -25 4 0 7 11 7 25 0 14 -3 25 -7 25 -5 0
-8 -11 -8 -25z"/>
<path d="M12357 526 c21 -28 24 -28 16 2 -3 12 -12 22 -20 22 -11 0 -10 -5 4
-24z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
